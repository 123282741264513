import classes from './Footer.module.css';
import footerLogo from '../../images/footerLogo.png';

const Footer = () => {
    return (
        <div className={classes.container}>
            <div className={classes.leftContainer}>
                <img src={footerLogo} alt={"Eezy Personnel"} />
            </div>
            <div className={classes.middleContainer}>
                <div className={classes.yTunnus}>
                    Y-tunnus: 2215096-6
                </div>
                <div >
                    <a
                        className={classes.link1}
                        href="https://personnel.eezy.fi/yhteystiedot/"
                        target="_blank"
                        rel='noreferrer'
                    >
                        Yhteystiedot »
                    </a>
                </div>
                <div className={classes.copyrightText}>
                    ©Copyright Eezy Personnel {new Date().getFullYear()}
                </div>
                <div className={classes.middleBottom}>
                    <a className={classes.link2}
                        href="https://personnel.eezy.fi/tietosuojaseloste/"
                        target="_blank"
                        rel='noreferrer'
                    >
                        Tietosuojaseloste
                    </a>

                    <a className={classes.link3}
                        href="https://eezy.fi/fi/yhtio/eezy/evasteiden-kaytto-eezy-konsernin-sivuilla%C2%A0"
                        target="_blank"
                        rel='noreferrer'
                    >
                        Evästetiedot ja käyttöehdot
                    </a>

                    <a className={classes.link4}
                        href="https://personnel.eezy.fi/#"
                        target="_blank"
                        rel='noreferrer'
                    >
                        Evästeasetukset
                    </a>
                </div>
            </div>
            <div className={classes.rightContainer} />
        </div>
    )
};

export default Footer;