import { t } from 'i18next';
import { toast } from 'react-toastify';

const toasted = () => {
    // Create error message about missing field data
    toast(t('errorMessage.fillAllFields'), {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: "error",
    });
}

export default toasted;
