import classes from './FormSteps.module.css';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from 'react-i18next';
import { useFormDataContext } from '../../../contexts/FormDataContext';
import FormStepButtons from '../FormStepButtons/FormStepButtons';
import toasted from '../../../scripts/toasted';

const FormStep4 = () => {

    const { formData, setFormData } = useFormDataContext();

    const { t } = useTranslation();

    const checkStepValidation = () => {
        if (formData.assesseeCanMeetOthers &&
            formData.reportLanguage
        ) {
            return true
        } else {
            toasted();
            return false
        }
    };

    return (
        <div className={classes.container}>
            <Box
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
            >
                <div className={classes.column}>
                    <div className={classes.textFullWidth}>
                        {t('formStep4.fieldTitle1')}
                        <div className={classes.extraContainer}>
                            <div
                                className={classes.redStar}>
                                *
                            </div>
                        </div>
                    </div>

                    <RadioGroup
                        className={classes.radioGroup}
                        value={formData.assesseeCanMeetOthers || "Ei merkitystä"}
                        onChange={(e) => {
                            setFormData(
                                {
                                    ...formData,
                                    assesseeCanMeetOthers: e.target.value
                                }
                            )
                        }}
                    >

                        <FormControlLabel checked={formData.assesseeCanMeetOthers === "Ei merkitystä"} value="Ei merkitystä" control={<Radio />} label="Ei merkitystä" />
                        <FormControlLabel checked={formData.assesseeCanMeetOthers === "Kyllä"} value="Kyllä" control={<Radio />} label="Kyllä" />
                    </RadioGroup>
                </div>

                <div className={classes.column}>
                    <div className={classes.textFullWidth}>
                        {t('formStep4.fieldTitle2')}
                        <div className={classes.extraContainer}>
                            <div
                                className={classes.redStar}>
                                *
                            </div>
                        </div>
                    </div>

                    <RadioGroup
                        className={classes.radioGroup}
                        value={formData.reportLanguage || "Suomi"}
                        onChange={(e) => {
                            setFormData(
                                {
                                    ...formData,
                                    reportLanguage: e.target.value
                                }
                            )
                        }}
                    >
                        <FormControlLabel checked={formData.reportLanguage === "Suomi"} value="Suomi" control={<Radio />} label="Suomi" />
                        <FormControlLabel checked={formData.reportLanguage === "Ruotsi"} value="Ruotsi" control={<Radio />} label="Ruotsi" />
                        <FormControlLabel checked={formData.reportLanguage === "Englanti"} value="Englanti" control={<Radio />} label="Englanti" />
                    </RadioGroup>

                </div>

                <div className={classes.column}>
                    <div className={classes.textFullWidth}>
                        {t('formStep4.fieldTitle3')}
                    </div>

                    <TextField
                        multiline
                        rows={4}
                        id="outlined-required"
                        label={`Mahdolliset lisätiedot`}
                        defaultValue={formData.assignmentAdditionalInfo || ''}
                        className={classes.textFieldStep4}
                        onChange={(e) => {
                            setFormData({
                                ...formData,
                                assignmentAdditionalInfo: e.target.value
                            })
                        }}
                    />
                </div>
            </Box>
            <FormStepButtons
                checkStepValidation={checkStepValidation}
            />
        </div>
    )
};

export default FormStep4;