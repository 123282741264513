import classes from './StepViewer.module.css';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useFormDataContext } from '../../contexts/FormDataContext';

const steps = [
    'Perustiedot',
    'Tehtävän tiedot',
    'Arvioitavat',
    'Arviointiprosessi',
    'Yhteenveto'
];

const StepViewer = () => {

    const { formData } = useFormDataContext();

    return (
        <div className={classes.container}>
            <Stepper
                activeStep={formData?.formStep || 0} alternativeLabel
            >
                {
                    steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))
                }
            </Stepper>
        </div>
    )
};

export default StepViewer;