import classes from './FormSteps.module.css';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';
import { useFormDataContext } from '../../../contexts/FormDataContext';
import FormStepButtons from '../FormStepButtons/FormStepButtons';
import { ChangeEvent, useState } from 'react';
import isEmail from 'validator/lib/isEmail';
import toasted from '../../../scripts/toasted';

const FormStep3 = () => {

    const [error, setError] = useState<boolean>(false);

    const { formData, setFormData } = useFormDataContext();

    const { t } = useTranslation();

    const checkStepValidation = () => {
        if (formData.canGiveDataToEezy &&
            formData.assessee.every(a => a.name !== '' && isEmail(a.eMail || '') && a.phone !== '')
        ) {
            setError(false);
            return true
        } else {
            setError(true);
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
            toasted();
            return false
        }
    };

    const changeAssesseeData = (property: string, index: number, element: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let newAssesseeArray = [...formData.assessee];
        if (property === "name") {
            newAssesseeArray[index].name = element.target.value;
        } else if (property === "email") {
            newAssesseeArray[index].eMail = element.target.value;
        } else if (property === "phone") {
            newAssesseeArray[index].phone = element.target.value;
        } else if (property === "additionalInfo") {
            newAssesseeArray[index].additionalInfo = element.target.value;
        };

        setFormData(
            {
                ...formData,
                assessee: [...newAssesseeArray]
            }
        )
    };

    const handleMinus = () => {
        // Copy assessee array from formData
        let cutAssesseeArray = formData.assessee;
        // Remove last item from array
        cutAssesseeArray.length > 1 && cutAssesseeArray.pop();
        // Set cut array as new formData assessee array
        setFormData(
            {
                ...formData,
                assessee: [...cutAssesseeArray]
            }
        )
    };

    const handlePlus = () => {
        // Add new empty assessee object to array
        setFormData(
            {
                ...formData,
                assessee: [...formData.assessee, { name: '', eMail: '', phone: '', additionalInfo: '' }]
            }
        )
    };

    return (
        <div className={classes.container}>
            <Box
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
            >
                <div className={classes.step3CheckboxMargin}>
                    <div className={classes.checkboxContainer}>
                        <div className={error && !formData.canGiveDataToEezy ? `${classes.wrapper} ${classes.error}` : classes.wrapper}>
                            <Checkbox
                                checked={formData.canGiveDataToEezy}
                                onChange={() => {
                                    setFormData({
                                        ...formData,
                                        canGiveDataToEezy: !formData.canGiveDataToEezy
                                    })
                                }}
                            />
                            <div className={classes.labelContainer}>
                                <div>Vakuutan, että minulla on lupa luovuttaa arvioitavien henkilöiden yhteystiedot Eezy Personnelille</div>
                                <div
                                    className={classes.redStar}>
                                    *
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classes.assesseeRow}>
                    <div className={classes.text}>
                        {t('formStep3.fieldTitle1')}
                    </div>
                    <div className={classes.counterContainer}>
                        <div
                            className={classes.counterButton}
                            onClick={() => { handleMinus() }}
                        >
                            -
                        </div>
                        <div className={classes.counterDigitalContainer}>
                            <div className={classes.counterDigital}>
                                {formData.assessee.length}
                            </div>
                        </div>
                        <div
                            className={classes.counterButton}
                            onClick={() => { handlePlus() }}
                        >
                            +
                        </div>
                    </div>
                </div>

                {
                    formData.assessee.map((a, i) => {
                        return (
                            <div
                                className={classes.assesseeItem}
                                key={i}
                            >
                                <div className={classes.row}>
                                    <div className={classes.text}>
                                        {`${t('formStep3.fieldTitle2')} ${i + 1}`}
                                    </div>
                                    <div className={classes.fieldContainerColumn}>
                                        <TextField
                                            required
                                            error={error && !formData.assessee[i].name}
                                            className={classes.textFieldStep1Column}
                                            id="outlined-disabled"
                                            label="Nimi"
                                            defaultValue={formData.assessee[i].name || ''}
                                            onChange={(e) => { changeAssesseeData("name", i, e) }}
                                        />
                                        <TextField
                                            required
                                            error={(error && !formData.assessee[i].eMail) || (error && !isEmail(formData.assessee[i].eMail || ''))}
                                            className={classes.textFieldStep1Column}
                                            id="outlined-disabled"
                                            label="Sähköposti"
                                            defaultValue={formData.assessee[i].eMail || ''}
                                            onChange={(e) => { changeAssesseeData("email", i, e) }}
                                        />
                                        <TextField
                                            required
                                            error={error && !formData.assessee[i].phone}
                                            className={classes.textFieldStep1Column}
                                            id="outlined-disabled"
                                            label="Puhelin"
                                            defaultValue={formData.assessee[i].phone || ''}
                                            onChange={(e) => { changeAssesseeData("phone", i, e) }}
                                        />
                                    </div>
                                </div>

                                <div className={classes.row}>
                                    <div className={classes.text}>
                                        {t('formStep3.fieldTitle3')}
                                    </div>
                                    <div className={classes.fieldContainerRow}>
                                        <TextField
                                            multiline
                                            rows={4}
                                            id="outlined-required"
                                            label={`Esimerkiksi arvioitavan äidinkieli, asuinpaikkakunta ja milloin arvioitavaan voi aikaisintaan olla yhteydessä`}
                                            defaultValue={formData.assessee[i].additionalInfo || ''}
                                            className={classes.textFieldStep2}
                                            onChange={(e) => { changeAssesseeData("additionalInfo", i, e) }}
                                        />
                                    </div>
                                </div>

                                {
                                    // Show gray bottom line only between items, not after last one
                                    formData.assessee.length > 1 && formData.assessee.length !== i + 1 &&
                                    <div className={classes.bottomLine} />
                                }
                            </div>
                        )
                    })
                }

            </Box>
            <FormStepButtons
                checkStepValidation={checkStepValidation}
            />
        </div>
    )
};

export default FormStep3;