import { createContext, useContext, useState } from 'react';
import { ContextProviderProps, FormData } from './interfaces';
import initialFormState from './initialFormState';

interface FormDataContextType {
    formData: FormData,
    setFormData: (val: FormData) => void;
};

export const FormDataContext = createContext({} as FormDataContextType);
export const useFormDataContext = () => useContext(FormDataContext);

export const FormDataContextProvider = ({ children }: ContextProviderProps) => {
    const [formData, setFormData] = useState<FormData>({ ...initialFormState });
    return <FormDataContext.Provider value={{ formData, setFormData }}>
        {children}
    </FormDataContext.Provider>
};

