import Axios from 'axios';
import config from '../config/config';
import { FormData } from '../contexts/interfaces';

const serverUrl = config.serverUrl;

const sendMail = async (
    formData: FormData
) => {
    try {
        const res = await Axios.post(`${serverUrl}/sendMail`,
            {
                ...formData
            });
        return res;
    } catch (err: any) {
        return err;
    }
};

export {
    sendMail
};