import { Route, Routes } from 'react-router-dom';
import './App.css';
import Footer from './components/Footer/Footer';
import TopBar from './components/TopBar/TopBar';
import InfoBubble from './components/InfoBubble/InfoBubble';
import FormView from './views/FormView/FormView';
import OrderCompleteView from './views/OrderCompleteView/OrderCompleteView';
import SelectPackageView from './views/SelectPackageView/SelectPackageView';
import SendMailView from './views/SendMailView/SendMailView';
import 'react-toastify/dist/ReactToastify.css';
//import TagManager from 'react-gtm-module'
/*
// Google Tag Manager
const tagManagerArgs = {
  gtmId: 'G-WZ9MNPWG2X'
};

TagManager.initialize(tagManagerArgs);
*/
const App = () => {
  /* console.log(window.dataLayer)
  window.dataLayer.push({
    event: 'Front Page'
  });*/

  return (
    <div className="App">
      <TopBar />
      <Routes>
        <Route path='/' element={<SelectPackageView />} />
        <Route path='/hakemus' element={<FormView />} />
        <Route path='/valmis' element={<OrderCompleteView />} />
        <Route path='/sendMail' element={<SendMailView />} />
      </Routes>
      <InfoBubble />
      <Footer />
    </div>
  );
};

export default App;