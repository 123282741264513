import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import config from '../config/config';

i18n.use(initReactI18next).init({
    fallbackLng: 'en_US',
    lng: config.language,
    resources: {
        fi_FI: {
            translations: require('./locales/fi_FI.json'),
        },
        en_US: {
            translations: require('./locales/en_US.json'),
        }
    },
    ns: ['translations'],
    defaultNS: 'translations',
});

export default i18n;