import classes from './TopBar.module.css';
import logo from '../../images/logo.png';

const TopBar = () => {
    return (
        <div className={classes.container}>
            <div className={classes.logoContainer}>
                <img
                    src={logo}
                    alt=''
                />
            </div>

        </div>
    )
};

export default TopBar;