import classes from "./InfoBubble.module.css";
import { BsQuestionCircle } from "react-icons/bs";
import { CgClose } from "react-icons/cg";
import { useState } from "react";

const InfoBubble = () => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <div
        className={classes.container}
        onClick={() => {
          setOpen(!open);
        }}
      >
        {open ? (
          <CgClose className={classes.icon} />
        ) : (
          <BsQuestionCircle className={classes.icon} />
        )}
      </div>
      {open && (
        <div className={classes.textContainer}>
          <div className={classes.questionText}>
            Tarvitsetko apua oikean palvelun valintaan tai tilauslomakkeen
            täyttöön?
          </div>
          <div className={classes.contactTextContainer}>
            <div>Ota yhteyttä:</div>
            <a
              className={classes.mailTo}
              href="mailto:tilaukset.personnel@eezy.fi"
            >
              tilaukset.personnel@eezy.fi
            </a>
            <div className={classes.phone}>tai 0108 20 1800</div>
          </div>
        </div>
      )}
    </>
  );
};

export default InfoBubble;
