import classes from './AssessmentProcess.module.css';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';

const AssessmentProcess = () => {

    const { t } = useTranslation();

    const sxTheme = {
        fontSize: 14,
        bgcolor: "rgb(239, 239, 244)",
        color: "black",
        boxShadow: "rgba(16, 24, 40, 0.1) 0px 4px 12px, rgba(129, 80, 190, 0.1) 0px 4px 12px",
        lineHeight: "24px",
        fontWeight: 400,
        fontFamily: "Quicksand"
    }

    return (
        <div className={classes.container}>
            <div className={classes.top}>
                <div className={classes.title}>
                    {t('assessmentProcess.title1')}
                </div>
                <div className={classes.body}>
                    {t('assessmentProcess.body1')}
                </div>
                <div className={classes.title2}>
                    {t('assessmentProcess.title2')}
                </div>
                <div className={classes.underText}>
                    *Vie hiiri aikajanan päälle nähdäksesi lisätietoja
                </div>
            </div>

            <div className={classes.processContainer}>
                <div className={classes.topBoxContainer}>
                    <Tooltip
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    ...sxTheme
                                }
                            },
                            arrow: {
                                sx: {
                                    color: "rgb(239, 239, 244)"
                                }
                            }
                        }}
                        title={t('assessmentProcess.tooltip3')}
                        placement={"top"}
                        arrow
                    >
                        <div className={classes.topBox1}>
                            <span className={classes.indTextTop}>
                                Yhteydenotto arvioitaviin
                            </span>
                            <span className={classes.lineTop} />
                            <span className={classes.dotTop} />
                        </div>
                    </Tooltip>

                    <Tooltip
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    ...sxTheme
                                }
                            },
                            arrow: {
                                sx: {
                                    color: "rgb(239, 239, 244)"
                                }
                            }
                        }}
                        title={t('assessmentProcess.tooltip5')}
                        placement={"top"}
                        arrow
                    >
                        <div className={classes.topBox2}>
                            <span className={classes.indTextTop}>
                                Arviointivaihe
                            </span>
                            <span className={classes.lineTop} />
                            <span className={classes.dotTop} />
                        </div>
                    </Tooltip>

                    <Tooltip
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    ...sxTheme
                                }
                            },
                            arrow: {
                                sx: {
                                    color: "rgb(239, 239, 244)"
                                }
                            }
                        }}
                        title={t("assessmentProcess.tooltip7")}
                        placement={"top"}
                        arrow
                    >
                        <div className={classes.topBox3}>
                            <span className={classes.indTextTop}>
                                Tulosten tulkinta ja analysointi
                            </span>
                            <span className={classes.lineTop} />
                            <span className={classes.dotTop} />
                        </div>
                    </Tooltip>
                </div>

                <div className={classes.middleWrapper}>
                    <Tooltip
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    ...sxTheme
                                }
                            },
                            arrow: {
                                sx: {
                                    color: "rgb(239, 239, 244)"
                                }
                            }
                        }}
                        title={t("assessmentProcess.tooltip1")}
                        placement={"top"}
                        arrow
                    >
                        <div className={classes.leftBox1}>
                            <span className={classes.indTextLeft}>
                                Tilaus
                            </span>
                            <span className={classes.lineLeft} />
                            <span className={classes.dotLeft} />
                            <span className={classes.lineLeftDotted} />
                        </div>
                    </Tooltip>

                    <div className={classes.stepContainer}>
                        <div className={classes.step1}>
                            <div className={classes.stepText}>1.-2. päivä</div>
                        </div>
                        <div className={classes.step2}>
                            <div className={classes.stepText}>2.-3. päivä</div>
                        </div>
                        <div className={classes.step3}>
                            <div className={classes.stepText}>3.-5. päivä</div>
                        </div>
                        <div className={classes.step4}>
                            <div className={classes.stepText}>5.-6. päivä</div>
                        </div>
                    </div>
                    <div className={classes.column}>
                        <Tooltip
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        ...sxTheme
                                    }
                                },
                                arrow: {
                                    sx: {
                                        color: "rgb(239, 239, 244)"
                                    }
                                }
                            }}
                            title={t("assessmentProcess.tooltip9")}
                            placement={"top"}
                            arrow
                        >
                            <div className={classes.rightBox1}>
                                <span className={classes.indTextRight}>
                                    Tulosten raportointi arvioiduille
                                </span>
                                <span className={classes.lineRight} />
                                <span className={classes.dotRight} />
                                <span className={classes.lineRightDotted} />
                            </div>
                        </Tooltip>
                        <Tooltip
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        ...sxTheme
                                    }
                                },
                                arrow: {
                                    sx: {
                                        color: "rgb(239, 239, 244)"
                                    }
                                }
                            }}
                            title={t("assessmentProcess.tooltip8")}
                            placement={"bottom"}
                            arrow
                        >
                            <div className={classes.bottomBox4}>
                                <span className={classes.dotBottom} />
                                <span className={classes.lineBottom} />
                                <span className={classes.indTextBottom}>
                                    Tulosten raportointi toimeksiantajalle
                                </span>
                            </div>
                        </Tooltip>
                    </div>

                </div>

                <div className={classes.bottomBoxContainer}>

                    <Tooltip
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    ...sxTheme
                                }
                            },
                            arrow: {
                                sx: {
                                    color: "rgb(239, 239, 244)"
                                }
                            }
                        }}
                        title={t("assessmentProcess.tooltip2")}
                        placement={"bottom"}
                        arrow
                    >
                        <div className={classes.bottomBox1}>
                            <span className={classes.dotBottom} />
                            <span className={classes.lineBottom} />
                            <span className={classes.indTextBottom}>
                                Konsultin yhteydenotto toimeksiantajaan
                            </span>
                        </div>
                    </Tooltip>

                    <Tooltip
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    ...sxTheme
                                }
                            },
                            arrow: {
                                sx: {
                                    color: "rgb(239, 239, 244)"
                                }
                            }
                        }}
                        title={t("assessmentProcess.tooltip4")}
                        placement={"bottom"}
                        arrow
                    >
                        <div className={classes.bottomBox2}>
                            <span className={classes.dotBottom} />
                            <span className={classes.lineBottom} />
                            <span className={classes.indTextBottom}>
                                Arviointiaikataulun vahvistus sähköpostitse toimeksiantajalle
                            </span>
                        </div>
                    </Tooltip>

                    <Tooltip
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    ...sxTheme
                                }
                            },
                            arrow: {
                                sx: {
                                    color: "rgb(239, 239, 244)"
                                }
                            }
                        }}
                        title={t("assessmentProcess.tooltip6")}
                        placement={"bottom"}
                        arrow
                    >
                        <div className={classes.bottomBox3}>
                            <span className={classes.dotBottom} />
                            <span className={classes.lineBottom} />
                            <span className={classes.indTextBottom}>
                                Palauteajankohdan sopiminen
                            </span>
                        </div>
                    </Tooltip>
                </div>
            </div>

        </div>
    )
};

export default AssessmentProcess;