import classes from './FormSteps.module.css';
import { useTranslation } from 'react-i18next';
import { useFormDataContext } from '../../../contexts/FormDataContext';
import FormStepButtons from '../FormStepButtons/FormStepButtons';
import React from 'react';
import {
    useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import { useCallback, useEffect } from 'react';

const FormStep5 = () => {
    const { formData } = useFormDataContext();

    const { t } = useTranslation();

    const checkStepValidation = () => {
        return true;
    };

    const { executeRecaptcha } = useGoogleReCaptcha();

    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        // Get token from successul execution of recaptcha
        const token = await executeRecaptcha('yourAction');

        // Do whatever you want with the token
    }, [executeRecaptcha]);

    // You can use useEffect to trigger the verification as soon as the component being loaded
    useEffect(() => {
        handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);

    return (
        <div className={classes.container}>
            <div className={classes.topLine} />
            <table className={classes.table}>
                <tbody>
                    <tr className={classes.tableBoldTitle}>
                        <td>Perustiedot</td>
                    </tr>

                    <tr>
                        <td className={classes.tableTitle}>
                            Yrityksen nimi ja Y-tunnus
                        </td>
                        <td className={classes.tableResult}>
                            {formData.organizationName}
                        </td>
                        <td className={classes.tableResult}>
                            {formData.yTunnus}
                        </td>
                    </tr>

                    <tr>
                        <td className={classes.tableTitle}>
                            Yhteyshenkilö
                        </td>
                        <td className={classes.tableResult}>
                            {formData.contactPersonName}
                        </td>
                        <td className={classes.tableResult}>
                            {formData.contactPersonEmail}
                        </td>
                        <td className={classes.tableResult}>
                            {formData.contactPersonPhone}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className={classes.bottomLine} />
            <table className={classes.table}>
                <tbody>
                    <tr className={classes.tableBoldTitle}>
                        <td>Tehtävän tiedot</td>
                    </tr>

                    <tr>
                        <td className={classes.tableTitle}>
                            Haettava tehtävänimike
                        </td>
                        <td className={classes.tableResult}>
                            {formData.assignmentTitle}
                        </td>
                    </tr>

                    <tr>
                        <td className={classes.tableTitle}>
                            Paikkakunta
                        </td>
                        <td className={classes.tableResult}>
                            {formData.city}
                        </td>
                    </tr>

                    <tr>
                        <td className={classes.tableTitle}>
                            Työtehtävien kuvaus
                        </td>
                        <td className={classes.tableResult}>
                            {formData.assignmentDescription}
                        </td>
                    </tr>
                </tbody>
            </table>

            <div className={classes.bottomLine} />

            <table className={classes.table}>
                <tbody>
                    <tr className={classes.tableBoldTitle}>
                        <td>Arvioitavat</td>
                    </tr>

                    <tr>
                        <td className={classes.tableTitle}>
                            Arvioitavien lukumäärä
                        </td>
                        <td className={classes.tableResult}>
                            {formData.assessee.length}
                        </td>
                    </tr>
                    {
                        // Render every assessee from formData assessee array
                        formData.assessee.map((a, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <tr>
                                        <td className={classes.tableTitle}>
                                            {`Arvioitava ${i + 1}`}
                                        </td>
                                        <td className={classes.tableResult}>
                                            {a.name}
                                        </td>
                                        <td className={classes.tableResult}>
                                            {a.eMail}
                                        </td>
                                        <td className={classes.tableResult}>
                                            {a.phone}
                                        </td>
                                    </tr>
                                    {
                                        a.additionalInfo ?
                                            <tr>
                                                <td />
                                                <td
                                                    colSpan={3}
                                                    className={classes.tableResultNotBolded}
                                                >
                                                    {a.additionalInfo}
                                                </td>
                                            </tr>
                                            : null
                                    }
                                </React.Fragment>
                            )
                        })
                    }
                </tbody>
            </table>

            <div className={classes.bottomLine} />

            <table className={classes.table}>
                <tbody>
                    <tr className={classes.tableBoldTitle}>
                        <td>Arviointiprosessi</td>
                    </tr>

                    <tr>
                        <td className={classes.tableTitle}>
                            Haluatko, että suunnittelemme arviointiprosessin niin, että arvioitavat eivät kohtaa toisiaan prosessin aikana?
                        </td>
                        <td className={classes.tableResult}>
                            {formData.assesseeCanMeetOthers}
                        </td>
                    </tr>

                    <tr>
                        <td className={classes.tableTitle}>
                            Henkilöarviointiraportin kieli
                        </td>
                        <td className={classes.tableResult}>
                            {formData.reportLanguage}
                        </td>
                    </tr>

                    <tr>
                        <td className={classes.tableTitle}>
                            Lisätiedot
                        </td>
                        <td className={classes.tableResultNotBolded}>
                            {formData.assignmentAdditionalInfo}
                        </td>
                    </tr>
                </tbody>
            </table>

            <FormStepButtons
                checkStepValidation={checkStepValidation}
            />
        </div>
    )
};

export default FormStep5;