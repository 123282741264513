import { useTranslation } from 'react-i18next';
import { BsArrowRight, BsFillCheckCircleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import classes from './OrderCompleteView.module.css';

const OrderCompleteView = () => {

    const { t } = useTranslation();

    const navigate = useNavigate();

    return (
        <div className={classes.container}>
            <BsFillCheckCircleFill
                className={classes.checkMark}
            />
            <div className={classes.thankYouText}>
                Tilaus tehty, kiitos!
            </div>
            <div className={classes.weWillContactText}>
                Olemme sinuun yhteydessä viimeistään seuraavana arkipäivänä.
            </div>
            <div className={classes.button}
                onClick={() => { navigate('/') }}
            >
                {t('orderComplete.newOrder')}
                <BsArrowRight
                    className={classes.arrowRight}
                />
            </div>

            <div className={classes.eezyAlsoOffersText}>
                Tiesithän, että Eezy tarjoaa myös
            </div>

            <div className={classes.offersContainer}>

                <div className={`${classes.offerItem} ${classes.purple}`}>
                    <div className={classes.offerItemTitle}>
                        Rekrytointipalvelut
                    </div>
                    <div className={classes.offerItemText}>
                        Ratkaisusi on Eezy, kun haet apua rekrytointiin, etsit luotettavaa vuokratyöntekijää tai haluat tasata kausivaihteluita ulkoistamalla henkilöstöä. Me olemme varmistamassa, että hakijat ja tekijät löytävät toisensa. Alan laajimman toimipisteverkostomme ansiosta ymmärrämme aina myös paikallista liiketoimintaa.
                    </div>
                    <div className={`${classes.button} ${classes.floatRight}`}
                        onClick={() => { window.open('https://eezy.fi/yrityksille/', '_blank', 'noopener,noreferrer') }}
                    >
                        {t('orderComplete.explore')}
                        <BsArrowRight
                            className={classes.arrowRight}
                        />
                    </div>
                </div>

                <div className={`${classes.offerItem} ${classes.green}`}>
                    <div className={classes.offerItemTitle}>
                        Suorahakupalvelut
                    </div>
                    <div className={classes.offerItemText}>
                        Suorahaku eli headhunting on täsmäratkaisu ylimmän johdon, päälliköiden ja vaativien asiantuntijatehtävien täyttämiseen. Suorahaku tuo onnistumisvarmuutta rekrytointiin, kun lähestytään kohdistetusti taustaltaan oikeita henkilöitä.  Huippuluokan research-tiimimme ja nykyaikaiset tekoälyä hyödyntävät työkalumme löytävät myös ne osaajat, jotka eivät juuri tällä hetkellä ole aktiivisessa työnhaussa.
                    </div>
                    <div className={`${classes.button} ${classes.floatRight}`}
                        onClick={() => { window.open('https://personnel.eezy.fi/tyonantajille/suorahaku/', '_blank', 'noopener,noreferrer') }}
                    >
                        {t('orderComplete.explore')}
                        <BsArrowRight
                            className={classes.arrowRight}
                        />
                    </div>
                </div>

                <div className={`${classes.offerItem} ${classes.orange}`}>
                    <div className={classes.offerItemTitle}>
                        Uudelleensijoituspalvelut
                    </div>
                    <div className={classes.offerItemText}>
                        Työntekijöille tarjottu uudelleensijoituspalvelu on vastuullisen työnantajan valinta. Uudelleensijoituksen tarkoitus on auttaa irtisanottavaa tai yhteisestä sopimuksesta irtisanoutuvaa työntekijää löytämään itselleen uusi ura tai työ. Eezyn uudelleensijoitus sisältää tavoitteellista ja yksilöllistä uraohjausta valmennettavan elämäntilanne huomioon ottaen.
                    </div>
                    <div className={`${classes.button} ${classes.floatRight}`}
                        onClick={() => { window.open('https://personnel.eezy.fi/tyonantajille/uudelleensijoitus/', '_blank', 'noopener,noreferrer') }}
                    >
                        {t('orderComplete.explore')}
                        <BsArrowRight
                            className={classes.arrowRight}
                        />
                    </div>
                </div>

            </div>
        </div>
    )
};

export default OrderCompleteView;