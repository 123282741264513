import './index.css';
import App from './App';
import { FormDataContextProvider } from './contexts/FormDataContext';
import ReactDOMClient from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import "./i18n/config";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ScrollToTop from './components/ScrollToTop';
import { ToastContainer } from 'react-toastify';

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container as HTMLElement);

// console.log = () => { };

root.render(
  <Router>
    <FormDataContextProvider>
      <ScrollToTop />
      <GoogleReCaptchaProvider
        reCaptchaKey="6Lff0WgjAAAAADPBmSp74JXmiNPOfnlURx-3CPPQ"
      >
        <ToastContainer />
        <App />
      </GoogleReCaptchaProvider>
    </FormDataContextProvider>
  </Router>
);

