import { useTranslation } from 'react-i18next';
import classes from './PackageDescriptions.module.css';

const PackageDescriptions = () => {

    const { t } = useTranslation();

    return (
        <div className={classes.container}>
            <table
                className={classes.table}
                cellSpacing={0}
            >
                <tbody>
                    <tr className={classes.topRow}>
                        <td className={classes.mainTitle} />
                        <td className={classes.mainTitle}>
                            {t('availablePackages.packageTitle1')}
                        </td>
                        <td className={classes.mainTitle}>
                            {t('availablePackages.packageTitle2')}
                        </td>
                        <td className={classes.mainTitle}>
                            {t('availablePackages.packageTitle3')}
                        </td>
                    </tr>

                    <tr className={classes.row}>
                        <td
                            className={classes.tableTopText}
                            colSpan={4}
                        >
                            Jokaiseen arviointiin sisältyy kriteerien määritys ja ehdokasprofiilin analysointi suhteessa asetettuihin vaatimuksiin psykologin toimesta
                        </td>
                    </tr>

                    <tr className={classes.row}>
                        <td className={classes.tableTitle}>
                            Psykologin haastattelu:
                        </td>
                        <td>
                            työpsykologinen haastattelu, perustaso
                        </td>
                        <td>
                            työpsykologinen haastattelu, laajennettu
                        </td>
                        <td>
                            työpsykologinen haastattelu, laaja-alainen
                        </td>
                    </tr>

                    <tr className={classes.row}>
                        <td className={classes.tableTitle}>
                            Ehdokkaan kykykapasiteetin ja ongelmanratkaisuvalmiuksien kartoitus:
                        </td>
                        <td>
                            perustaso
                        </td>
                        <td>
                            laajennettu
                        </td>
                        <td>
                            laaja-alaisesti, strategisen ajattelukyvyn valmiudet
                        </td>
                    </tr>

                    <tr className={classes.row}>
                        <td className={classes.tableTitle}>
                            Motivaation kartoitus:
                        </td>
                        <td>
                            tehtäväkohtainen motivaatio ja sitoutumisennuste
                        </td>
                        <td>
                            tehtäväkohtainen motivaatio ja sitoutumisennuste + yleiset motivaatiotekijät
                        </td>
                        <td>
                            tehtäväkohtainen motivaatio ja sitoutumisennuste + yleiset motivaatiotekijät
                        </td>
                    </tr>

                    <tr className={classes.row}>
                        <td className={classes.tableTitle}>
                            Työpersoonallisuuden kartoitus:
                        </td>
                        <td>
                            perustaso
                        </td>
                        <td>
                            laajennettu
                        </td>
                        <td>
                            laaja
                        </td>
                    </tr>

                    <tr className={classes.row}>
                        <td className={classes.tableTitle}>
                            Vuorovaikutusvalmiuksien kartoitus:
                        </td>
                        <td>
                            perustaso
                        </td>
                        <td>
                            laajennettu
                        </td>
                        <td>
                            laaja, sis. kriteerilähtöisesi esim. neuvotteluvalmiuksien, esiintymisvalmiuksien, verkottumistaitojen arvioinnin
                        </td>
                    </tr>

                    <tr className={classes.row}>
                        <td className={classes.tableTitle}>
                            Erityisvalmiuksien kartoitus:
                        </td>
                        <td>

                        </td>
                        <td>
                            Tehtävän kriteerien asettamien erityisvalmiuksien kartoitus, esim. myyntivalmiudet, projektinvetovalmiudet, lähijohtamisvalmiudet
                        </td>
                        <td>
                            Johtamisvalmiuksien laaja-alainen arviointi, sisältäen kriteerilähtöisesti esim. johtamistyylin, päätöksentekokyvykkyyden, muutosten johtamisen ja uudistamisen edellytysten arvioinnin
                        </td>
                    </tr>

                    <tr>
                        <td className={`${classes.tableTitle} ${classes.bottomRow}`}>
                            Raportointi:
                        </td>
                        <td className={classes.bottomRow}>
                            Kirjallinen raportti ja tarvittaessa suullinen palaute toimeksiantajalle
                        </td>
                        <td className={classes.bottomRow}>
                            Kirjallinen raportti ja tarvittaessa suullinen palaute toimeksiantajalle
                        </td>
                        <td className={classes.bottomRow}>
                            Kirjallinen raportti ja suullinen palaute toimeksiantajalle
                        </td>
                    </tr>

                    <tr>
                        <td className={classes.bottomRow}>

                        </td>
                        <td className={classes.bottomRow}>
                            Kirjallinen raportti ja mahdollisuus henkilökohtaiseen, valmentavaan palautteeseen ehdokkaalle
                        </td>
                        <td className={classes.bottomRow}>
                            Kirjallinen raportti ja mahdollisuus henkilökohtaiseen, valmentavaan palautteeseen ehdokkaalle
                        </td>
                        <td className={classes.bottomRow}>
                            Kirjallinen raportti ja henkilökohtainen, valmentava palaute ehdokkaalle
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
};

export default PackageDescriptions;