const initialFormState = {
    canGiveDataToEezy: false,
    securityAndServicesChecked: false,
    assessee: [{
        name: '',
        eMail: '',
        phone: ''
    }]
};

export default initialFormState;