import classes from './FormSteps.module.css';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { useFormDataContext } from '../../../contexts/FormDataContext';
import FormStepButtons from '../FormStepButtons/FormStepButtons';
import { useState } from 'react';
import toasted from '../../../scripts/toasted';

const FormStep2 = () => {

    const [error, setError] = useState<boolean>(false);

    const { formData, setFormData } = useFormDataContext();

    const { t } = useTranslation();

    const checkStepValidation = () => {
        if (formData.city &&
            formData.assignmentTitle &&
            formData.assignmentDescription
        ) {
            setError(false);
            return true
        } else {
            setError(true);
            // Show error message
            toasted();
            return false
        }
    };

    return (
        <div className={classes.container}>
            <Box
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
            >
                <div className={classes.row}>
                    <div className={classes.textColumn}>
                        <div className={classes.text}>
                            {t('formStep2.fieldTitle1')}
                        </div>
                        <div className={classes.textDesc}>
                            Jolla tehtävään haettava henkilö(t) ensisijaisesti työskentelee
                        </div>
                    </div>

                    <div className={classes.fieldContainerRow}>
                        <TextField
                            required
                            error={error && !formData.city}
                            id="outlined-required"
                            label={`Paikkakunta`}
                            defaultValue={formData.city || ''}
                            className={classes.textFieldStep2}
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    city: e.target.value
                                })
                            }}
                        />
                    </div>
                </div>

                <div className={classes.row}>
                    <div className={classes.textColumn}>
                        <div className={classes.textFullWidth}>
                            {t('formStep2.fieldTitle2')}
                        </div>
                    </div>

                    <div className={classes.fieldContainerRow}>
                        <TextField
                            required
                            error={error && !formData.assignmentTitle}
                            id="outlined-required"
                            label={`Tehtävänimike`}
                            defaultValue={formData.assignmentTitle || ''}
                            className={classes.textFieldStep2}
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    assignmentTitle: e.target.value
                                })
                            }}
                        />
                    </div>
                </div>

                <div className={classes.row}>
                    <div className={classes.textColumn}>
                        <div className={classes.text}>
                            {t('formStep2.fieldTitle3')}
                        </div>
                        <div className={classes.textDesc}>
                            Ajankäyttö prosentteina merkittävimpien työtehtävien osalta
                            (esimerkiksi suunnittelu, organisointi, johtaminen, myynti,
                            asiakaspalvelu, kehitys, koulutus, esiintyminen yms.)
                        </div>
                    </div>

                    <div className={classes.fieldContainerRow}>
                        <TextField
                            required
                            multiline
                            rows={4}
                            error={error && !formData.assignmentDescription}
                            id="outlined-required"
                            label={`Kuvaile haettavan roolin keskeisiä työtehtäviä`}
                            defaultValue={formData.assignmentDescription || ''}
                            className={classes.textFieldStep2}
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    assignmentDescription: e.target.value
                                })
                            }}
                        />
                    </div>
                </div>
            </Box>
            <FormStepButtons
                checkStepValidation={checkStepValidation}
            />
        </div>
    )
};

export default FormStep2;