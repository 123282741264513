import AssessmentProcess from '../../components/AssessmentProcess/AssessmentProcess';
import AvailablePackages from '../../components/AvailablePackages/AvailablePackages';
import PackageDescriptions from '../../components/PackageDescriptions/PackageDescriptions';
import classes from './SelectPackageView.module.css';

const SelectPackageView = () => {
    return (
        <div className={classes.container}>
            <AssessmentProcess />
            <AvailablePackages />
            <PackageDescriptions />
        </div>
    )
};

export default SelectPackageView;