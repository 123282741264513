import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useFormDataContext } from '../../contexts/FormDataContext';
import classes from './AvailablePackages.module.css';

const AvailablePackages = () => {

    const { formData, setFormData } = useFormDataContext();

    const { t } = useTranslation();

    const navigate = useNavigate();

    const handleClick = (selectedPackage: number, title: string) => {
        // Update form with selected package number and title
        setFormData(
            {
                ...formData,
                selectedPackage: selectedPackage,
                selectedPackageTitle: title,
                formStep: 0
            }
        );
        // Navigate to form page
        navigate('/hakemus');
    };

    return (
        <div className={classes.container}>
            <div className={classes.title}>
                {t('availablePackages.title')}
            </div>
            <div className={classes.body}>
                {t('availablePackages.body')}
            </div>

            <div className={classes.packageContainer}>
                <div className={classes.package}>
                    <div className={classes.packageTitle}>
                        {t('availablePackages.packageTitle1')}
                    </div>
                    <div className={classes.packagePrice}>
                        <div className={classes.currency}>
                            €
                        </div>
                        {t('availablePackages.packagePrice1')}
                    </div>
                    <div className={classes.packageBody}>
                        {t('availablePackages.packageBody1')}
                    </div>
                    <div
                        className={classes.button}
                        onClick={() => { handleClick(1, t('availablePackages.packageTitle1') || 'Asiantuntijataso') }}
                    >
                        {t('availablePackages.selectButton')}
                    </div>
                </div>

                <div className={classes.package}>
                    <div className={classes.packageTitle}>
                        {t('availablePackages.packageTitle2')}
                    </div>
                    <div className={classes.packagePrice}>
                        <div className={classes.currency}>
                            €
                        </div>
                        {t('availablePackages.packagePrice2')}
                    </div>
                    <div className={classes.packageBody}>
                        {t('availablePackages.packageBody2')}
                    </div>
                    <div
                        className={classes.button}
                        onClick={() => { handleClick(2, t('availablePackages.packageTitle2') || 'Päällikkö/Vaativa erityisasiantuntijataso') }}
                    >
                        {t('availablePackages.selectButton')}
                    </div>
                </div>

                <div className={classes.package}>
                    <div className={classes.packageTitle}>
                        {t('availablePackages.packageTitle3')}
                    </div>
                    <div className={classes.packagePrice}>
                        <div className={classes.currency}>
                            €
                        </div>
                        {t('availablePackages.packagePrice3')}
                    </div>
                    <div className={classes.packageBody}>
                        {t('availablePackages.packageBody3')}
                    </div>
                    <div
                        className={classes.button}
                        onClick={() => { handleClick(3, t('availablePackages.packageTitle3' || 'Johtotaso')) }}
                    >
                        {t('availablePackages.selectButton')}
                    </div>
                </div>
            </div>

            <div className={classes.underText}>
                *Ennen laskutusta tarkistamme mahdollisen sopimushintasi.
            </div>

        </div>

    )
};

export default AvailablePackages;