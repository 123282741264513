import * as React from "react";
import { useFormDataContext } from "../contexts/FormDataContext";

export default function ScrollToTop() {

    const { formData } = useFormDataContext();

    // Causes scroll to top effect whenever formStep changes
    React.useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [formData.formStep]);

    return null;
};