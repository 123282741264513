import { useTranslation } from 'react-i18next';
import { useFormDataContext } from '../../../contexts/FormDataContext';
import packagePrices from '../../../config/packagePrices';
import classes from './FormMainTitle.module.css';
import { useEffect } from 'react';

interface PackagePrices {
    1: string,
    2: string,
    3: string
};

const FormMainTitle = () => {

    const { formData, setFormData } = useFormDataContext();
    const { t } = useTranslation();

    useEffect(() => {
        // Re-calculate totalPrice every time selected package or amount of assessee changes
        setFormData(
            {
                ...formData,
                totalPrice: Number(packagePrices[formData.selectedPackage as keyof PackagePrices]) * formData.assessee.length
            }
        )
    }, [formData.assessee.length, formData.selectedPackage])

    const titles = [
        'Perustiedot',
        'Haettavan tehtävän tiedot',
        'Arvioitavien tiedot',
        'Arviointiprosessin tiedot',
        'Tilauksen yhteenveto'
    ];

    return (
        <div className={classes.container}>
            <div className={classes.title}>
                {titles[formData?.formStep || 0]}
            </div>
            <div className={classes.chosenPackageContainer}>
                <div className={classes.row}>
                    <div className={classes.chosenPackage}>
                        {`${t('formMainTitle.chosenPackage')}: `}
                    </div>
                    <div className={classes.selectedPackageTitle}>
                        <div>
                            {`${formData.selectedPackageTitle} ${formData.formStep === 4 ? '- €' + formData.totalPrice : ''}`}
                        </div>

                    </div>
                </div>
                {formData.formStep === 4 && <div className={classes.underText}>
                    *Ennen laskutusta tarkistamme mahdollisen sopimushintasi.
                </div>}

            </div>
        </div>
    )
};

export default FormMainTitle;