import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FormMainTitle from '../../components/Form/FormMainTitle/FormMainTitle';
import FormStep1 from '../../components/Form/FormSteps/FormStep1';
import FormStep2 from '../../components/Form/FormSteps/FormStep2';
import FormStep3 from '../../components/Form/FormSteps/FormStep3';
import FormStep4 from '../../components/Form/FormSteps/FormStep4';
import FormStep5 from '../../components/Form/FormSteps/FormStep5';
import StepViewer from '../../components/StepViewer/StepViewer';
import { useFormDataContext } from '../../contexts/FormDataContext';
import classes from './FormView.module.css';

const FormView = () => {

    const { formData } = useFormDataContext();

    const navigate = useNavigate();

    useEffect(() => {
        formData.selectedPackage === undefined && navigate('/');
    }, []);



    // Depending on formData step, render correct component
    const renderCurrentStep = () => {
        switch (formData.formStep) {
            case 0:
                return <FormStep1 />;
            case 1:
                return <FormStep2 />;
            case 2:
                return <FormStep3 />;
            case 3:
                return <FormStep4 />;
            case 4:
                return <FormStep5 />;
            default:
                return <FormStep1 />;
        };
    };

    return (
        <div className={classes.container}>
            <StepViewer />
            <FormMainTitle />
            {renderCurrentStep()}
        </div>
    )
};

export default FormView;