import classes from './FormSteps.module.css';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';
import { useFormDataContext } from '../../../contexts/FormDataContext';
import FormStepButtons from '../FormStepButtons/FormStepButtons';
import isEmail from 'validator/lib/isEmail';
import { useState } from 'react';
import toasted from '../../../scripts/toasted';

const FormStep1 = () => {

    const [error, setError] = useState<boolean>(false);

    const { formData, setFormData } = useFormDataContext();

    const { t } = useTranslation();

    const checkStepValidation = () => {
        if (formData.organizationName &&
            formData.yTunnus &&
            formData.contactPersonName &&
            isEmail(formData.contactPersonEmail || '') && // validate email address
            formData.contactPersonPhone &&
            formData.securityAndServicesChecked
        ) {
            setError(false);
            return true
        } else {
            setError(true);
            // Show error message
            toasted();
            return false
        }
    };

    return (
        <div className={classes.container}>
            <Box
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
            >
                <div className={classes.row}>
                    <div className={classes.text}>
                        <div className={classes.titleText}>
                            {t('formStep1.fieldTitle1')}
                        </div>
                        <div className={classes.extraContainer}>
                            <div
                                className={classes.redStar}>
                                *
                            </div>
                        </div>
                    </div>
                    <div className={classes.fieldContainerRow}>
                        <TextField
                            required
                            error={error && !formData.organizationName}
                            id="outlined-required"
                            label={`Organisaation nimi`}
                            defaultValue={formData.organizationName || ''}
                            className={classes.textFieldStep1}
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    organizationName: e.target.value
                                })
                            }}
                        />
                        <TextField
                            required
                            error={error && !formData.yTunnus}
                            id="outlined-required"
                            label={`Y-tunnus`}
                            defaultValue={formData.yTunnus || ''}
                            className={classes.textFieldStep1}
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    yTunnus: e.target.value
                                })
                            }}
                        />
                    </div>
                </div>
                <div className={classes.row}>
                    <div className={classes.text}>
                        {t('formStep1.fieldTitle2')}
                    </div>
                    <div className={classes.fieldContainerColumn}>
                        <TextField
                            required
                            error={error && !formData.contactPersonName}
                            className={classes.textFieldStep1Column}
                            id="outlined-disabled"
                            label="Nimi"
                            defaultValue={formData.contactPersonName || ''}
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    contactPersonName: e.target.value
                                })
                            }}
                        />
                        <TextField
                            required
                            error={(error && !formData.contactPersonEmail) || (error && !isEmail(formData.contactPersonEmail || ''))}
                            className={classes.textFieldStep1Column}
                            id="outlined-disabled"
                            label="Sähköposti"
                            defaultValue={formData.contactPersonEmail || ''}
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    contactPersonEmail: e.target.value
                                })
                            }}
                        />
                        <TextField
                            required
                            error={error && !formData.contactPersonPhone}
                            className={classes.textFieldStep1Column}
                            id="outlined-disabled"
                            label="Puhelin"
                            defaultValue={formData.contactPersonPhone || ''}
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    contactPersonPhone: e.target.value
                                })
                            }}
                        />
                    </div>
                </div>

                <div className={classes.checkboxContainer}>
                    <div className={error && !formData.securityAndServicesChecked ? `${classes.wrapper} ${classes.error}` : classes.wrapper}>
                        <Checkbox
                            checked={formData.securityAndServicesChecked}
                            onChange={() => {
                                setFormData({
                                    ...formData,
                                    securityAndServicesChecked: !formData.securityAndServicesChecked
                                });
                            }}
                        />
                        <div className={classes.labelContainer}>
                            <div>Olen tutustunut</div>
                            <a
                                href="https://personnel.eezy.fi/tietosuojaseloste/yritysasiakkaiden-tietosuojaseloste-2/"
                                target="_blank"
                                rel='noreferrer'
                            >
                                tietosuojaselosteeseen
                            </a>
                            <div
                                className={classes.redStar}>
                                *
                            </div>
                        </div>
                    </div>
                </div>
            </Box>
            <FormStepButtons
                checkStepValidation={checkStepValidation}
            />
        </div>
    )
};

export default FormStep1;