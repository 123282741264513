import { useTranslation } from 'react-i18next';
import { useFormDataContext } from '../../../contexts/FormDataContext';
import classes from './FormStepButtons.module.css';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import initialFormState from '../../../contexts/initialFormState';
import { sendMail } from '../../../dataHandlers/dataHandlers';

interface Props {
    checkStepValidation: () => boolean
};

const FormStepButtons = ({ checkStepValidation }: Props) => {

    const { formData, setFormData } = useFormDataContext();

    const { t } = useTranslation();

    const navigate = useNavigate();

    const handleNext = async () => {
        // Step 4 next button is 'Send'
        if (formData?.formStep === 4 && checkStepValidation()) {
            // Request sendMail datahandler with formData
            const response = await sendMail(formData);

            if (response?.data?.statusCode === 200) {
                // TODO: Temporary workaround as initialState still left assessee data for round 2
                let defaultState = { ...initialFormState };
                defaultState.assessee = [{
                    name: '',
                    eMail: '',
                    phone: ''
                }]
                // End of workaround

                // Clear form
                setFormData({ ...defaultState });
                // Navigate to orderCompleted -view
                navigate('/valmis');
            } else {
                // TODO: Sending email failed message
            }
        } else if (formData?.formStep !== undefined &&
            checkStepValidation()) {
            setFormData(
                {
                    ...formData,
                    formStep: formData.formStep + 1
                }
            )
        }
    };

    const handlePrevious = () => {
        if (formData?.formStep !== undefined && formData.formStep > 0) {
            // Just reduce step counter, if step is bigger than 0
            setFormData({
                ...formData,
                formStep: formData.formStep - 1
            });
        } else if (formData?.formStep === 0) {
            // Clear selected package data from form
            setFormData({
                ...formData,
                selectedPackage: undefined,
                selectedPackageTitle: ''
            });
            // Navigate to main route
            navigate('/');
        }
    };

    return (
        <div className={classes.container}>
            <div
                className={classes.buttonPrevious}
                onClick={() => { handlePrevious() }}
            >
                <BsArrowLeft
                    className={classes.arrowLeft}
                />
                {t('formStepButtons.previous')}
            </div>
            <div
                className={classes.button}
                onClick={() => { handleNext() }}
            >
                {formData.formStep === 4 ? t('formStepButtons.sendOrder') : t('formStepButtons.next')}
                <BsArrowRight
                    className={classes.arrowRight}
                />
            </div>
        </div>
    )
};

export default FormStepButtons;